<template>
  <div class="addProduct">
    <div class="container-fluid container-edit pt-5 pb-4">
      <h1 class="text-center title-mobile pb-2">{{$t("addProductHeader")}}</h1>
    </div>
    <form id="app" @submit.prevent="handleSubmit">
      <div class="container-fluid pt-5 pr-5 pl-5">
        <div class="row">
          <div class="col-xl-4 col-lg-12 col-xs-12 container-mobile-content">
            <div class="form-group">
              <label for="nameFr">{{$t("productNameLabel")}} <span>*</span>
                <b-popover target="popover-target-1" triggers="hover" placement="top">
                  {{openff.name.fr}}
                </b-popover>
                <b-button v-show="showBtnNameFrOff" @click="product.name.fr = openff.name.fr" id="popover-target-1" type="button" pill class="my-2 mx-1 edit-ean btnOpff btnOpenFFOutline" >
                  <img src="@/assets/img/logoOpenFF.png" alt="" width="20px" class="imgOpenff pr-1 pb-1">
                  {{openff.name.fr | truncate(15, '...') }}
                </b-button></label>
              <input type="text" v-model="product.name.fr" class="form-control" name="nameFr" id="nameFr" placeholder="nom" :class="{ 'is-invalid': submitted && $v.product.name.fr.$error }">
              <div v-if="submitted && !$v.product.name.fr.required" class="invalid-feedback">{{$t("fillFieldError")}}</div>
              <small id="nameFr" class="form-text text-muted">{{$t("french")}}</small>
            </div>
            <div class="form-group">
              <label for="nameEn">{{$t("productNameLabel")}} <span>*</span>
                <b-popover target="popover-target-2" triggers="hover" placement="top">
                  {{openff.name.en}}
                </b-popover>
                <b-button v-show="showBtnNameEnOff" @click="product.name.en = openff.name.en" id="popover-target-2" type="button" pill class="my-2 mx-1 edit-ean btnOpff btnOpenFFOutline" >
                  <img src="@/assets/img/logoOpenFF.png" alt="" width="20px" class="imgOpenff pr-1 pb-1">
                  {{openff.name.en  | truncate(15, '...')}}
                </b-button></label>
              <input type="text" v-model="product.name.en" class="form-control" name="nameEn" id="nameEn" placeholder="name" :class="{ 'is-invalid': submitted && $v.product.name.en.$error }">
              <div v-if="submitted && !$v.product.name.en.required" class="invalid-feedback">{{$t("fillFieldError")}}</div>
              <small id="nameEn" class="form-text text-muted">{{$t("english")}}</small>
            </div>
            <div class="form-group">
              <label for="marque">{{$t("productBrandLabel")}} <span>*</span>
                <b-popover target="popover-target-3" triggers="hover" placement="top">
                  {{openff.brand}}
                </b-popover>
                <b-button v-show="showBtnBrandOff" @click="product.marque = openff.brand" id="popover-target-3" type="button" pill class="my-2 mx-1 edit-ean btnOpff btnOpenFFOutline">
                  <img src="@/assets/img/logoOpenFF.png" alt="" width="20px" class="imgOpenff pr-1 pb-1">
                  {{openff.brand | truncate(15, '...') }}
                </b-button></label>
              <input type="text" v-model="product.marque" class="form-control" name="marque" id="marque" :class="{ 'is-invalid': submitted && $v.product.marque.$error }">
              <div v-if="submitted && !$v.product.marque.required" class="invalid-feedback">{{$t("fillFieldError")}}</div>
            </div>
            <div class="form-group">
              <label for="price">{{$t("productPriceLabel")}} <span>*</span></label>
              <input type="number" v-model="product.price" class="form-control" min="0" step="0.01" name="price" id="price" :class="{ 'is-invalid': submitted && $v.product.price.$error }">
              <div v-if="submitted && !$v.product.price.required" class="invalid-feedback">{{$t("fillFieldError")}}</div>
            </div>
            <!-- <div class="row">
              <div class="form-group col-6">
                <label for="price">Prix <span>*</span></label>
                <input type="number" v-model="product.price" class="form-control" min="0" step="0.01" name="price" id="price" :class="{ 'is-invalid': submitted && $v.product.price.$error }">
                <div v-if="submitted && !$v.product.price.required" class="invalid-feedback">Veuillez remplir ce champs</div>
              </div>
              <div class="form-group col-6">
                <label for="stock">Stock</label>
                <input type="number" v-model="product.stock" class="form-control" min="1" name="stock" id="stock" value="1" placeholder="1" disabled>
              </div>
            </div> -->
          </div>
          <div class="col-xl-4 col-lg-12 col-xs-12 container-mobile-content">
            <div class="form-group">
              <label for="category">{{$t("productCategoryLabel")}} <span>*</span></label>
              <b-form-input list="list-cat" v-model="product.category" id="category" :placeholder="$t('productChooseCategoryPlaceHolder')" :class="{ 'is-invalid': submitted && $v.product.category.$error }"></b-form-input>
              <b-form-datalist id="list-cat" :options="categories" text-field="name" value-field="name"></b-form-datalist>
              <div v-if="submitted && !$v.product.category.required" class="invalid-feedback">{{$t('productChooseCategoryError')}}</div>
            </div>
            <div class="form-group">
              <label for="descriptionFr">{{$t("productDescriptionLabel")}}
                <b-popover target="popover-target-4" triggers="hover" placement="top">
                  {{openff.description.fr}}
                </b-popover>
                <b-button v-show="showBtnDescriptionFrOff" @click="product.description.fr = openff.description.fr" type="button" id="popover-target-4" pill variant="outline-info" class="my-2 mx-1 edit-ean btnOpff2 btnOpenFFOutline">
                  <img src="@/assets/img/logoOpenFF.png" alt="" width="20px" class="imgOpenff pr-1 pb-1">
                  {{openff.description.fr }}
                </b-button>
              </label>
              <textarea  row="6" v-model="product.description.fr" class="form-control textarea-add" name="descriptionFr" id="descriptionFr"></textarea>
              <small id="descriptionFr" class="form-text text-muted">{{$t("french")}}</small>
            </div>
            <div class="form-group">
              <label for="descriptionEn">{{$t("productDescriptionLabel")}}
                <b-popover target="popover-target-5" triggers="hover" placement="top">
                  {{openff.description.en}}
                </b-popover>
                <b-button v-show="showBtnDescriptionEnOff" @click="product.description.en = openff.description.en" type="button" id="popover-target-5" pill class="my-2 mx-1 edit-ean btnOpff2 btnOpenFFOutline">
                  <img src="@/assets/img/logoOpenFF.png" alt="" width="20px" class="imgOpenff pr-1 pb-1">
                  {{ openff.description.en }}
                </b-button>
              </label>
              <textarea  row="6" v-model="product.description.en" class="form-control textarea-add" name="descriptionEn" id="descriptionEn"></textarea>
              <small id="descriptionEn" class="form-text text-muted">{{$t("english")}}</small>
            </div>
          </div>
          <div class="col-xl-4 col-lg-12 col-xs-12 container-mobile-content">
            <div class="form-group">
              <label for="keyWordsFr">
                {{$t('productKeywordsLabel')}}
                <b-button type="button" class="btn btn-info info-bubble" style="padding: 0px 0px !important;" v-b-popover.hover.top="$t('productKeywordsInfo')">
                  <i class="fas fa-info-circle"></i>
                </b-button>
              </label>
              <input  type="text" v-model="product.keywords.fr" class="form-control" name="keyWordsFr" id="keyWordsFr">
              <small id="keyWordsFr" class="form-text text-muted">{{$t("french")}} {{$t('productKeywordsExplain')}} </small>
            </div>
            <div class="form-group">
              <label for="keyWordsEn">
                {{$t('productKeywordsLabel')}}
                <b-button type="button" class="btn btn-info info-bubble" style="padding: 0px 0px !important;" v-b-popover.hover.top="$t('productKeywordsInfo')">
                  <i class="fas fa-info-circle"></i>
                </b-button>
              </label>
              <input  type="text" v-model="product.keywords.en" class="form-control" name="keyWordsEn" id="keyWordsEn">
              <small id="keyWordsEn" class="form-text text-muted">{{$t("english")}} {{$t('productKeywordsExplain')}} </small>
            </div>
            <div class="form-group">
              <label for="ean">{{$t('productEanLabel')}} <span>*</span></label>
              <input type="text" v-model="product.ean" class="form-control" name="ean" id="ean" :class="{ 'is-invalid': submitted && $v.product.ean.$error }">
              <div v-if="submitted && !$v.product.ean.required" class="invalid-feedback">{{$t('productEanError')}}</div>
            </div>
            <div class="row">
              <div v-if="showMessageNoProductFound" class="title-nothing pl-3 pb-3">
                <span class="errorMessage"><i class="fas fa-info-circle"></i> {{$t('noProductFoundOpenff')}}</span>
              </div>
            </div>
            <div class="row">           
              <div class="col-xl-5 col-lg-12 col-xs-12 pt-5">
                <div class="form-group col-xs-12">
                  <button type="button" v-on:click.prevent="generateEan" class="btn btn-dark edit-ean">{{$t('productGenerateEanButton')}} <i class="fas fa-chevron-right ml-2"></i></button>
                </div>
              </div>
              <div class="col-xl-7 col-lg-12 col-xs-12 pt-3 d-flex justify-content-center">
                <div class="form-group col-xs-12">
                  <img class="barcode" id="barcode" hidden/>
                </div>
              </div>
            </div>
            <div class="row">          
              <div v-if="showBtnOff" v-show="showBtnOff" pill class="col-xl-5 col-lg-12 col-xs-12 form-group" >
                <a :href="`https://fr.openfoodfacts.org/produit/${product.ean}`" target="_blank" class="btn btn-info btnOpenFF">
                  <img src="@/assets/img/logoOpenFF.png" alt="" width="25px" class="imgOpenff pr-1 pb-1"> 
                  {{$t('seeToOpenff')}}
                  <i class="fas fa-chevron-right ml-2"></i>
                </a>                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid pb-3 container-mobile-content">
        <div class="row pt-1 d-flex justify-content-center align-items-center">
          <div class="form-group">
            <b-popover target="popover-target-7" triggers="hover" placement="top" boundary="window">
              <img width="150px" height="auto" :src="openff.image_thumb" class="imgOpenff">
            </b-popover>
            <b-button v-show="showBtnImgOff" @click="loadImg" id="popover-target-7" style="padding: 0px 0px !important;" type="button" variant="info" class="info-image">
              <img src="@/assets/img/image-search-outline.png" alt="image de recherche open foodfact" class="imgOpenff">
            </b-button>
            <small class="form-text text-muted pl-4 pb-1">{{$t('productImageSizeInfo')}}</small>           
            <input type="file" class="input-file" @change="onFileSelected" name="image" id="image">
            <p class="file-label">{{$t('insertImage')}} <i class="fas fa-image ml-2"></i></p>
          </div>
          <div class="pl-3 pb-4">
            <img class="round" width="130px" height="auto" :src="''">
          </div>
        </div>
      </div>
      <div class="container-fluid pt-5 pb-5 container-mobile specification-container">
        <div class="row d-flex justify-content-center container-margin-right">
          <h1 class="text-center title-mobile pb-4 title-inverse">{{$t('productAddSpecification')}}</h1>
        </div>
        <div class="row col-12 d-flex justify-content-center pt-3 pb-4">
          <div class="form-group col-xl-1 col-lg-1 col-xs-12 pt-2 mr-3">
            <h4 class="text-light">{{$t('productSpecificationAssociate')}}</h4>
          </div>
          <div class="form-group col-xl-3 col-lg-3 col-xs-12 mr-3">
              <input type="text" v-model="product.parentSpecificationId" class="form-control specification-bar" name="parentSpecificationId" id="parentSpecificationId" :placeholder="$t('productSpecificationAssociateProductId')">
          </div>
          <div class="col-xl-1 col-lg-1 col-xs-12">
            <b-button type="button" class="info-btn" v-b-popover.hover.top="$t('productSpecificationAssociateExplain')">
              <i class="fas fa-info"></i>
            </b-button>
          </div>
        </div>
        <div v-show="showSpe" class="specification-second">
          <div v-for="(option, id) in speListSelected" :key="id" class="row col-12 d-flex justify-content-center pt-3 pb-3">
            <div class="form-group col-xl-1 col-lg-1 col-xs-12 pt-2 mr-3">
              <h4 class="text-light" v-if="option == 'productSpecificationSlotKey'">{{$t('productSpecificationSlotLabel')}}</h4>
              <h4 class="text-light" v-if="option == 'productSpecificationETicketKey'">{{$t('productSpecificationETicketLabel')}}</h4>
              <h4 class="text-light" v-if="option == 'productSpecificationServiceKey'">{{$t('productSpecificationServiceLabel')}}</h4>
              <h4 class="text-light" v-if="option == 'productSpecificationContractKey'">{{$t('productSpecificationContractLabel')}}</h4>
              <h4 class="text-light" v-if="option == 'productSpecificationReferenceKey'">{{$t('productSpecificationReferenceLabel')}}</h4>
              <h4 class="text-light" v-if="option == 'productSpecificationSizeKey'">{{$t('productSpecificationSizeLabel')}}</h4>
              <h4 class="text-light" v-if="option == 'productSpecificationColorKey'">{{$t('productSpecificationColorLabel')}}</h4>
              <h4 class="text-light" v-if="option == 'productSpecificationStateKey'">{{$t('productSpecificationStateLabel')}}</h4>
            </div>
            <div v-if="option == 'productSpecificationSlotKey'" class="form-group col-xl-3 col-lg-3 col-xs-12 mr-3">
              <select class="form-control specification-bar" v-model="product.slot" name="slot" id="slot" :selected="undefined">
                <option :value="undefined" >{{$t('chooseAnOption')}}</option>
                <option :value="true">{{$t('enabled')}}</option>
                <option :value="false">{{$t('disabled')}}</option>
              </select>
            </div>
            <div v-if="option == 'productSpecificationETicketKey'" class="form-group col-xl-3 col-lg-3 col-xs-12 mr-3">
              <select class="form-control specification-bar" v-model="product.eticket" name="eticket" id="eticket" :selected="undefined">
                <option :value="undefined" >{{$t('chooseAnOption')}}</option>
                <option :value="true">{{$t('enabled')}}</option>
                <option :value="false">{{$t('disabled')}}</option>
              </select>
            </div>
            <div v-if="option == 'productSpecificationServiceKey'" class="form-group col-xl-3 col-lg-3 col-xs-12 mr-3">
              <select class="form-control specification-bar" v-model="product.service" name="service" id="service" :selected="undefined">
                <option :value="undefined" >{{$t('chooseAnOption')}}</option>
                <option :value="true">{{$t('enabled')}}</option>
                <option :value="false">{{$t('disabled')}}</option>
              </select>
            </div>
            <div v-if="option == 'productSpecificationContractKey'" class="form-group col-xl-3 col-lg-3 col-xs-12 mr-3">
              <select class="form-control specification-bar" v-model="product.contract" name="contract" id="contract" :selected="undefined">
                <option :value="undefined">{{$t('chooseAnOption')}}</option>
                <option :value="true">{{$t('enabled')}}</option>
                <option :value="false">{{$t('disabled')}}</option>
              </select>
            </div>
              <div v-if="option == 'productSpecificationReferenceKey'" class="form-group col-xl-3 col-lg-3 col-xs-12 mr-3">
                  <input type="text" v-model="product.reference" class="form-control specification-bar" name="reference" id="reference">
              </div>
            <div v-if="option == 'productSpecificationSizeKey'" class="col-xl-3 col-lg-3 col-xs-12 pt-1 pb-1 mr-3">
              <button type="button" id="xs" :class="{ 'selected': product.selected.XS }" v-on:click.prevent="selectedSize('XS')" class="btn btn-outline-light round pr-3 pl-3 mr-2">XS</button>
              <button type="button" id="s" :class="{ 'selected': product.selected.S }" v-on:click.prevent="selectedSize('S')" class="btn btn-outline-light round pr-3 pl-3 mr-2">S</button>
              <button type="button" id="m" :class="{ 'selected': product.selected.M }" v-on:click.prevent="selectedSize('M')" class="btn btn-outline-light round pr-3 pl-3 mr-2">M</button>
              <button type="button" id="l" :class="{ 'selected': product.selected.L }" v-on:click.prevent="selectedSize('L')" class="btn btn-outline-light round pr-3 pl-3 mr-2">L</button>
              <button type="button" id="xl" :class="{ 'selected': product.selected.XL }" v-on:click.prevent="selectedSize('XL')" class="btn btn-outline-light round pr-3 pl-3 mr-2">XL</button>
              <button type="button" id="xxl" :class="{ 'selected': product.selected.XXL }" v-on:click.prevent="selectedSize('XXL')" class="btn btn-outline-light round pr-3 pl-3">XXL</button>
            </div>
            <div v-if="option == 'productSpecificationStateKey'" class="form-group col-xl-3 col-lg-3 col-xs-12 mr-3">
              <select class="form-control specification-bar" v-model="product.state" name="state" id="state" :selected="undefined">
                <option :value="undefined">{{$t('chooseAnOption')}}</option>
                <option :value="true">{{$t('Enable')}}</option>
                <option :value="false">{{$t('Disable')}}</option>
              </select>
            </div>
            <div v-if="option == 'productSpecificationColorKey'" class="form-group col-xl-3 col-lg-3 col-xs-12 mr-3">
              <div class="row">
                <div class="col-6">
                  <input type="text" v-model="product.color.fr" class="form-control specification-bar" name="colorFr" id="colorFr" placeholder="Couleur">
                </div>
                <div class="col-6">
                  <input type="text" v-model="product.color.en" class="form-control specification-bar" name="colorEn" id="colorEn" placeholder="Color">
                </div>
              </div>
              <div class="row col-12 pt-3 d-flex justify-content-center">
                <a class="colors-link" v-on:click.prevent="colorsChange('Blanc', 'White')"><div class="colors" style="background-color: white"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Rouge', 'Red')"><div class="colors" style="background-color: red"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Orange', 'Orange')"><div class="colors" style="background-color: orange"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Jaune', 'Yellow')"><div class="colors" style="background-color: yellow"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Vert', 'Green')"><div class="colors" style="background-color: Chartreuse"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Bleu Marine', 'Aquamarine')"><div class="colors" style="background-color: aquamarine"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Cyan', 'Cyan')"><div class="colors" style="background-color: cyan"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Bleu Ciel', 'DeepSkyBlue')"><div class="colors" style="background-color: DeepSkyBlue"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Bleu', 'Blue')"><div class="colors" style="background-color: blue"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Violet', 'Violet')"><div class="colors" style="background-color: BlueViolet"></div></a>
                <a class="colors-link" v-on:click.prevent="colorsChange('Noir', 'Black')"><div class="colors" style="background-color: Black"></div></a>
              </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-xs-12 pt-2">
              <button type="button" v-if="option == 'productSpecificationSlotKey'" v-on:click.prevent="removeSpe('productSpecificationSlotKey')" class="btn btn-danger"><i class="fas fa-times"></i></button>
              <button type="button" v-if="option == 'productSpecificationETicketKey'" v-on:click.prevent="removeSpe('productSpecificationETicketKey')" class="btn btn-danger"><i class="fas fa-times"></i></button>
              <button type="button" v-if="option == 'productSpecificationServiceKey'" v-on:click.prevent="removeSpe('productSpecificationServiceKey')" class="btn btn-danger"><i class="fas fa-times"></i></button>
              <button type="button" v-if="option == 'productSpecificationContractKey'" v-on:click.prevent="removeSpe('productSpecificationContractKey')" class="btn btn-danger"><i class="fas fa-times"></i></button>
              <button type="button" v-if="option == 'productSpecificationReferenceKey'" v-on:click.prevent="removeSpe('productSpecificationReferenceKey')" class="btn btn-danger"><i class="fas fa-times"></i></button>
              <button type="button" v-if="option == 'productSpecificationSizeKey'" v-on:click.prevent="removeSpe('productSpecificationSizeKey')" class="btn btn-danger"><i class="fas fa-times"></i></button>
              <button type="button" v-if="option == 'productSpecificationColorKey'" v-on:click.prevent="removeSpe('productSpecificationColorKey')" class="btn btn-danger"><i class="fas fa-times"></i></button>
              <button type="button" v-if="option == 'productSpecificationStateKey'" v-on:click.prevent="removeSpe('productSpecificationStateKey')" class="btn btn-danger"><i class="fas fa-times"></i></button>
            </div>
          </div>
        </div>
        <div v-show="showAdd">
          <div class="row d-flex justify-content-center pt-3 container-margin-right">
            <button type="button" v-if="speList.length != 0" v-on:click.prevent="specificationOnChange(true)" class="btn btn-outline-light round pr-3">
                {{$t('productAddSpecification')}}
                <i class="fas fa-plus ml-2"></i>
            </button>
          </div>
        </div>
        <div class="container">
          <div class="row d-flex justify-content-center pt-3">
            <div v-show="showButton" class="specification-first">
              <button v-for="(option, id) in speList" :key="id" type="button" v-on:click.prevent="selectedSpecification(option)" class="btn btn-outline-light round mb-3 pr-3 mr-3">
                {{$t(option)}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid container-mobile-content pt-5 pb-3 bg-dark">
        <div>
          <div class="container col-12 d-flex justify-content-center pb-4">
            <h1 class="text-center title-mobile pb-2 title-inverse">{{$t('productCrossSellId')}}</h1>
          </div>
          <div class="container col-12 d-flex justify-content-center pb-5">
            <div class="row col-xl-7 col-lg-7 col-xs-12 d-flex justify-content-center">
              <div class="input-group col-xl-7 col-lg-7 col-xs-12">
                <input type="text" :placeholder="$t('productSearchPlaceHolder')" v-model="search" class="form-control specification-bar" name="search" id="search">
                <div class="input-group-append">
                  <button v-on:keyup.enter="searchProducts()" class="search-btn" v-on:click.prevent="searchProducts()"><i class="fas fa-search"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="search">
            <div v-if="searchSelected" class="container-fluid table-container col-xl-10 col-lg-10 col-xs-12 pt-2">
              <table class="table text-light">
                <tbody v-for="(product, id) in productsFilter" :key="id">
                  <tr :id="product.id">
                    <td class="table-id">{{product.id}}</td>
                    <td class="table-name">{{product.name.fr}}</td>
                    <td class="table-name table-en">{{product.name.en}}</td>
                    <td>{{product.category.name[$i18n.locale]}}</td>
                    <td class="d-flex justify-content-center">
                      <button type="button" v-on:click.prevent="addCrossSellID(product)" class="btn btn-primary mr-2"><i class="fas fa-plus"></i></button>
                      <button type="button" v-on:click.prevent="removeCrossSellID(product.id)" class="btn btn-danger ml-2"><i class="fas fa-times"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="notEnoughCaracts" class="pt-5">
            <h3 class="text-center title-mobile pb-2 title-inverse">{{$t('productSearchExplain2')}}</h3>
          </div>
          <div v-if="isEmpty" class="pt-5">
            <h3 class="text-center title-mobile pb-2 title-inverse">{{$t('noResultFound')}}</h3>
          </div>
          <div class="container col-12 pt-5 d-flex justify-content-center">
            <div class="row col-xl-7 col-lg-7 col-xs-12 d-flex justify-content-center">
              <span v-for="(product, id) in product.productsId" :key="id" class="badge badge-pill badge-primary mt-3 mr-3">
                <a v-on:click.prevent="removeCrossSellID(product.id)">
                  {{product.name[$i18n.locale]}}
                  <i class="fas fa-times ml-2"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid container-mobile pb-3 pr-5 pl-5 bg-dark">
        <div class="row">
          <div class="col-12 d-flex justify-content-center">
            <div class="form-group">
              <button type="submit" class="btn btn-primary pr-3">{{$t('send')}} <i class="fas fa-pencil-alt ml-2"></i></button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- <Setting :location="'product/add'" /> -->
  </div>
</template>

<script>

var utils = require('@/assets/js/utils');
import { required } from "vuelidate/lib/validators";
import Setting from '@/components/Settings.vue';


export default {
  name : 'AddProduct',
  components:{
    Setting,
  },
  data() {
    return {
      product: {
        name: {fr: '', en: ''},
        keywords: {fr: '', en: ''},
        description: {fr: '', en: ''},
        marque: '',
        price: 0,
        stock: 1,
        category: undefined,
        ean: null,
        image: null,
        imageBase64: null,
        parentSpecificationId: '',
        slot: undefined,
        eticket: undefined,
        service: undefined,
        contract: undefined,
        state: undefined,
        reference: undefined,
        size: [],
        selected: {XS: false, S: false, M: false, L: false, XL: false, XXL: false},
        color: {fr: '', en: ''},
        productsId: [],
      },
      showBtnDescriptionFrOff: false,
      showBtnDescriptionEnOff: false,
      showBtnNameFrOff: false,
      showBtnNameEnOff: false,
      showBtnBrandOff: false,
      showBtnImgOff: false,
      showMessageNoProductFound: false,
      openff: {
        name:{fr: null ,en: null},
        brand: null,
        description:{fr: '' ,en: ''},
        image: null,
        image_thumb: null
      },
      categories: [],
      submitted: false,
      showBtnOff: false,
      selectedSpe: undefined,
      showButton: true,
      showAdd: false,
      showSpe: false,
      speList: ['productSpecificationSlotKey', 'productSpecificationETicketKey', 'productSpecificationServiceKey', 'productSpecificationContractKey', 'productSpecificationReferenceKey', 'productSpecificationSizeKey', 'productSpecificationColorKey','productSpecificationStateKey'],
      speListSelected:[],
      productsFilter: [],
      search: null,
      searchSelected: false,
      notEnoughCaracts: true,
      isEmpty: false,
      url: utils.getConfig().URL,
    }
  },
  validations: {
    product: {
      name: { fr: {required}, en: {required} },
      marque: { required },
      price: { required },
      category: { required },
      ean: { required }
    }
  },
  methods: {
    getID(name){
      var cat = this.categories.find(c => c.name == name)
      if(cat != undefined){
        return cat.id;
      }
      else{ return null; }
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();

    },
    loadImg(){
      let self = this
      this.product.image = this.openff.image

      this.toDataURL(this.product.image, function(dataUrl) {
        let result;
        console.log(dataUrl)
        result = dataUrl
        $(".round").attr("src", result);
        $(".round").css("margin-left", "1rem");
        self.product.imageBase64 = result.split(',')[1];
      })
      
    },
    onFileSelected(event){
      let result;
      this.product.image = event.target.files[0]

      var reader = new FileReader();
      reader.readAsDataURL(this.product.image);
      reader.onload =  (e) => {
        result = e.target.result;
        $(".round").attr("src", result);
        $(".round").css("margin-left", "1rem");
        this.product.imageBase64 = result.split(',')[1];
        console.log(this.product.imageBase64);
        console.log(this.product.image);
      };
    },
    generateEan: function(){
      this.product.ean = utils.generateEan(this.product.ean).toString();
    },
    specificationOnChange: function(choix){
      if(choix){
        $(".specification-first").fadeIn(400);
        this.showButton = true;
        this.showAdd = false;
      }
      else{
        $(".specification-first").fadeOut(400);
        this.showButton = false;
        this.showAdd = true;
        this.showSpe = true;
        $(".specification-second").fadeIn(400);
      }
    },
    selectedSpecification: function(value){
      this.selectedSpe = value; // Specification choisie
      this.speListSelected.push(this.selectedSpe);

      this.specificationOnChange(false); // Affichage du choix

      var element = this.speList.indexOf(this.selectedSpe);
      this.speList.splice(element, 1); // Suppresion de l'élément dans la liste
    },
    colorsChange: function(fr, en){
      this.product.color.fr = fr;
      this.product.color.en = en;
    },
    selectedSize: function(selectedSize){
      let inArray = false;
      let count = 0;

      this.product.size.filter((element)=>{
        if(element == selectedSize){
          this.product.size.splice(count, 1);
          this.product.selected[`${selectedSize}`] = false;
          inArray = true;
        }
        count++;
      })

      if(!inArray){
        this.product.size.push(selectedSize);
        this.product.selected[`${selectedSize}`] = true;
      }
    },
    removeSpe(value){
      var element = this.speListSelected.indexOf(value);
      this.speListSelected.splice(element, 1); // Suppresion de l'élément dans la liste

      this.speList.push(value);

      if(value == "productSpecificationETicketKey")
        this.product.eticket = undefined
      if(value == "productSpecificationServiceKey")
        this.product.service = undefined
      else if(value == "productSpecificationSlotKey")
        this.product.slot = undefined
      else if(value == "productSpecificationContractKey")
        this.product.contract = undefined
      else if(value == "productSpecificationReferenceKey")
        this.product.reference = undefined
      else if(value == "productSpecificationSizeKey"){
        this.product.size = [];
        this.product.selected = {XS: false, S: false, M: false, L: false, XL: false, XXL: false};
      }
      else if(value == "productSpecificationColorKey"){
        this.product.color.fr = ''
        this.product.color.en = ''
      }else if(value == "productSpecificationStateKey"){
        this.product.state = undefined
      }
    },
    searchProducts(){
      if(this.search){
        if(this.search.length >= 3){
          this.notEnoughCaracts = false;
          this.isEmpty = false;

          let loader = this.$loading.show({
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel,
            backgroundColor: '#000000',
            color: '#fff',
            opacity: 0.7,
            width: 100,
            height: 100,
          });

          this.$http.get(this.url + 'search?keyterm=' + this.search)
          .then(function(response){
            this.productsFilter = response.data;
            this.searchSelected = true;
            loader.hide();

            if(this.productsFilter.length == 0){
              this.isEmpty = true;
            }
          })
        }
        else{
          this.productsFilter = [];
          this.notEnoughCaracts = true;
        }
      }

    },
    addCrossSellID(p){
      let inArray = false;
      this.product.productsId.filter((element)=>{
        if(element.id == p.id){
          inArray = true;
        }
      })
      if(!inArray){
        this.product.productsId.push(p);
      }

      $("#" + p.id).css("transition", "0.4s");
      $("#" + p.id).css("background-color", "#007bff");
    },
    removeCrossSellID(id){
      let count = 0;
      this.product.productsId.filter((element)=>{
        if(element.id == id){
          this.product.productsId.splice(count, 1);
        }
        count++;
      })
      $("#" + id).css("transition", "0.4s");
      $("#" + id).css("background-color", "transparent");
    },
    handleSubmit(e){
      this.submitted = true;
      var category = this.getID(this.product.category);

      if(category == null){
        this.product.category = undefined;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      else{
        var dataToInsert = {
          name:[
            {"lang": "fr", "value": this.product.name.fr},
            {"lang": "en", "value": this.product.name.en},
          ],
          marque : this.product.marque,
          price : this.product.price,
          category_id: category
        }

        // if(this.product.stock != 0){
        //   dataToInsert.stock = this.product.stock;
        // }
        if(this.product.productsId != undefined){
          let tab = [];
          this.product.productsId.filter((element)=>{
              tab.push(element.id);
          })

          dataToInsert.productsCrossSell = tab;
        }
        if(this.product.keywords.fr != "" || this.product.keywords.en != ""){
          dataToInsert.keywords = [
            {"lang": "fr", "value": this.product.keywords.fr},
            {"lang": "en", "value": this.product.keywords.en},
          ]
        }
        if(this.product.description.fr || this.product.description.en){
          dataToInsert.description = [];
          if(this.product.description.fr != "" && this.product.description.fr != undefined){
            dataToInsert.description.push({"lang": "fr", "value": this.product.description.fr});
          }
          if(this.product.description.en != "" && this.product.description.en != undefined){
            dataToInsert.description.push({"lang": "en", "value": this.product.description.en});
          }
        }
        if(this.product.ean != ""){
          dataToInsert.ean = this.product.ean
        }
        if(this.product.imageBase64 != null){
          dataToInsert.imageBase64 = this.product.imageBase64;
        }
        if(this.product.parentSpecificationId != ""){
          dataToInsert.parentSpecificationId = this.product.parentSpecificationId;
        }

        let speData = [];

        if(this.product.slot != undefined){
          let slotData = {
            specificationKey: "SLOT",
            value:[
              {"lang": "fr", "value": this.product.slot},
              {"lang": "en", "value": this.product.slot},
            ],
          }
          speData.push(slotData);
        }
        if(this.product.eticket != undefined){
          let eticketData = {
            specificationKey: "ETICKET",
            value:[
              {"lang": "fr", "value": this.product.eticket},
              {"lang": "en", "value": this.product.eticket},
            ],
          }
          speData.push(eticketData);
        }
        if(this.product.service != undefined){
          let serviceData = {
            specificationKey: "SERVICE",
            value:[
              {"lang": "fr", "value": this.product.service},
              {"lang": "en", "value": this.product.service},
            ],
          }
          speData.push(serviceData);
        }
        if(this.product.contract != undefined){
          let contractData = {
            specificationKey: "CONTRACT",
            value:[
              {"lang": "fr", "value": this.product.contract},
              {"lang": "en", "value": this.product.contract},
            ],
          }
          speData.push(contractData);
        }
        if(this.product.reference != undefined){
          let referenceData = {
            specificationKey: "REFERENCE",
            value:[
              {"lang": "fr", "value": this.product.reference},
              {"lang": "en", "value": this.product.reference},
            ],
          }
          speData.push(referenceData);
        }
        if(this.product.size.length != 0){
          let sizeData = {
            specificationKey: "SIZE",
            value:[
              {"lang": "fr", "value": this.product.size.toString()},
              {"lang": "en", "value": this.product.size.toString()},
            ],
          }
          speData.push(sizeData);
        }
        if(this.product.color.fr != '' && this.product.color.en != ''){
          let colorData = {
            specificationKey: "COLOR",
            value:[
              {"lang": "fr", "value": this.product.color.fr},
              {"lang": "en", "value": this.product.color.en},
            ],
          }
          speData.push(colorData);
        }
        if(this.product.state != undefined){
          let stateDate = {
            specificationKey: "STATE",
            value:[
              {"lang": "fr", "value": this.product.state},
              {"lang": "en", "value": this.product.state},
            ],
          }
          speData.push(stateDate);
        }

        dataToInsert.specifications = speData;

        const formData = new FormData();
        console.log(JSON.stringify(dataToInsert));
        formData.append('params', JSON.stringify(dataToInsert));

        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: '#000000',
          color: '#fff',
          opacity: 0.7,
          width: 100,
          height: 100,
        });

        this.$http.post(this.url + 'catalog/product/save', formData)
        .then(function(response){
          console.log(response)
          if(response.body.error == true){
            alert(response.body.message);
            this.product.ean = undefined;
          }
          else{
            const id = response.data.idProduct;
            window.location = "/success/" + id;
          }
          loader.hide();
        })
        .catch(function (e){
          console.log(e);
        })
      }
    },
  },
  mounted(){
    this.$http.get(utils.getConfig().URL + 'catalog/category/get/all/formated')
    .then((response) => {
      this.categories = response.data;
    });
  },
  watch:{
    'product.ean': function (val) {
      console.log(val)
      this.product.ean = val
      if(val.length > 1){
        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel,
          backgroundColor: '#000000',
          color: '#fff',
          opacity: 0.7,
          width: 100,
          height: 100,
        });
        this.$http.get(`https://world.openfoodfacts.org/api/v0/product/${val}.json`)
          .then(response => {
            loader.hide();
            this.showMessageNoProductFound = true
            if(response.data.status_verbose == "product found" && response.data.status == 1){
              this.showMessageNoProductFound = false
              let productInfos = response.data.product

              if(utils.isNotNull(productInfos.ingredients_text_fr)){           
                this.openff.description.fr = productInfos.ingredients_text_fr
                this.showBtnDescriptionFrOff = true
              }else{
                this.openff.description.fr = ''
                this.showBtnDescriptionFrOff = false

              }

              if(utils.isNotNull(productInfos.ingredients_text)){
                this.openff.description.en = productInfos.ingredients_text
                this.showBtnDescriptionEnOff = true
              }else{
                this.openff.description.en = ''
                this.showBtnDescriptionEnOff = false

              }

              if(utils.isNotNull(productInfos.brands)){
                this.openff.brand = productInfos.brands
                this.showBtnBrandOff = true
              }else{
                this.openff.brand = ''
                this.showBtnBrandOff = false
              }

              if(utils.isNotNull(productInfos.product_name_fr)){
                this.openff.name.fr = productInfos.product_name_fr
                this.showBtnNameFrOff = true
              }else{
                this.openff.name.fr = ''
                this.showBtnNameFrOff = false
              }

              if(utils.isNotNull(productInfos.product_name)){
                this.openff.name.en = productInfos.product_name
                this.showBtnNameEnOff = true
              }else{
                this.openff.name.en = ''
                this.showBtnNameEnOff = false
              }

              if(utils.isNotNull(productInfos.image_front_thumb_url)){
                this.openff.image_thumb = productInfos.image_front_thumb_url
                this.openff.image = productInfos.image_front_small_url
                this.showBtnImgOff = true
              }else{
                this.openff.image_thumb = ''
                this.openff.image = ''
                this.showBtnImgOff = false
              }

              this.showBtnOff = true             

            }else{
              this.showBtnOff = false 
              this.showBtnDescriptionFrOff = false
              this.showBtnDescriptionEnOff = false
              this.showBtnNameFrOff = false
              this.showBtnNameEnOff = false
              this.showBtnBrandOff = false
              this.showBtnImgOff = false
            }
        })
      }else{
        this.showMessageNoProductFound = false
        this.openff.description.fr = ''
        this.openff.description.en = ''
        this.openff.brand = ''
        this.openff.name.fr = ''
        this.openff.name.en = ''
        this.openff.image_thumb = ''
        this.openff.image = ''
      }
    }
      
  }
}
</script>


